import { useState, useMemo, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { OnboardingIntegrationCards, Pulse } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  ExplorerActions,
  ExplorerInteractions,
  activateOnboarding,
  completeActionList,
  deActivateOnboarding,
  setInteractions,
  stopOnboarding,
} from 'App/redux/onboardingSlice';
import { setInfoPanelOpenValue } from 'Storage/pages/StoragePage/StoragePageSlice';
import { useOnboardingStatusMutation } from 'App/redux/onboardingApi';
import { OnboardingOverlay, WelcomeScreen } from '_common/components';
import {
  OnboardingIntegrationCardsProps,
  OnboardingHandler,
} from 'dodoc-design-system/build/types/Components/OnboardingIntegrationCards/OnboardingIntegrationCards';
import {
  navigateToGroups,
  navigateToMyFiles,
  navigateToObject,
  navigateToShared,
  navigateToSpaces,
  navigateToTenantSettings,
} from 'router/history';
import { setMenuActiveOption } from '_common/components/Sidebar/SidebarSlice';
import ReactDOM from 'react-dom';
import { closeModal } from '_common/modals/ModalsSlice';
import { selectUserIsAdmin, useGetCurrentUserQuery } from '_common/services/api/authority';
import { InstanceService } from '_common/services';
import { clearSelection } from 'PDF/redux/PDFAnnotationsSlice';
import { removeFromList } from '_common/components/Table/TableSlice';
import { closeAllModals } from '_common/modals/ModalsSlice';

type CardId =
  | 'beginning'
  | 'sidebar_myFiles_description'
  | 'sidebar_shared_description'
  | 'sidebar_spaces_description'
  | 'sidebar_groups_description'
  | 'sidebar_completed'
  | 'action_bar_description'
  | 'action_bar_location'
  | 'action_bar_new_location'
  | 'action_bar_document_location'
  | 'action_bar_document_create'
  | 'action_bar_share_location'
  | 'action_bar_share_with_user'
  | 'action_bar_share_completed'
  | 'action_bar_new_space_location'
  | 'action_bar_space_create'
  | 'action_bar_create_document_description'
  | 'action_bar_create_document_completed'
  | 'tenant_settings_location'
  | 'tenant_settings_description'
  | 'tenant_settings_general_description'
  | 'tenant_settings_users_description'
  | 'tenant_settings_roles_description'
  | 'tenant_settings_tags_description'
  | 'tenant_settings_templates_description'
  | 'tenant_settings_referencesStyles_description'
  | 'tenant_settings_completed'
  | 'ending';

type ExtendedCard = OnboardingIntegrationCardsProps['cards'][number] & {
  id: CardId;
  deps?: ExplorerActions[];
  interactions?: ExplorerInteractions[];
  pulseTarget?: (
    | 'action_bar_new'
    | 'action_bar_new_document'
    | 'action_bar_new_document_option'
    | 'action_bar_document_name'
    | 'action_bar_document_created'
    | 'action_bar_share'
    | 'action_bar_share_user_name'
    | 'action_bar_share_user_role'
    | 'action_bar_share_finished'
    | 'action_bar_new_space'
    | 'action_bar_space_name'
    | 'action_bar_space_created'
    | 'action_bar_spaces_share'
    | 'action_bar_spaces_share_user_name'
    | 'action_bar_spaces_share_user_role'
    | 'action_bar_spaces_share_finished'
    | 'action_bar_spaces_share_closed'
    | 'action_bar_space_location'
    | 'tenant_settings_location'
  )[];
  //Action to be executed when a refresh is made and this card is skipped
  autoAction?: () => void;
  //Action to be executed as soon as this card is rendered
  onRender?: () => void;
};

type VisibleSpace = OnboardingIntegrationCardsProps['cards'][number]['visibleSpace'];
type Journey = 'personalUser' | 'collaborativeUser';

const OnboardingExplorer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onboardingRef = useRef<OnboardingHandler>(null);
  const [sendOnboardingStatus] = useOnboardingStatusMutation();
  const { isSuccess: userLoaded } = useGetCurrentUserQuery();
  const data = useSelector((state) => state.app.data);
  const active = useSelector((state) => state.onboarding.active.explorer);
  const started = useSelector((state) => state.onboarding.started.explorer);
  const [skipping, setSkipping] = useState(false);
  const initialPhase = useSelector((state) => state.onboarding.initialPhase.explorer);
  const currentPage = useSelector((state) => state.app.currentPage);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const explorerFiles = useSelector((state) => state.onboarding.explorerFiles);
  const currentUserIdAdmin = useSelector(selectUserIsAdmin);
  const [currentJourney, setCurrentJourney] = useState<Journey>();
  const [pulsePosition, setPulsePosition] = useState<
    Onboarding.PulseProps & {
      boundary: HTMLElement | null | undefined;
    }
  >();
  const collaborative = currentJourney === 'collaborativeUser';

  useEffect(() => {
    if (active) {
      dispatch(setInfoPanelOpenValue(false));
      dispatch(clearSelection());
    }
  }, [active]);

  const phases = useMemo(() => {
    const staticPhases: OnboardingIntegrationCardsProps['phases'] = {
      sidebar: {
        order: 1,
        label: intl.formatMessage({ id: 'UNDERSTANDING_THE_SIDEBAR' }),
      },
      spaces: {
        order: 2,
        label: collaborative
          ? intl.formatMessage({ id: 'GETTING_STARTED_WITH_SPACES' })
          : intl.formatMessage({ id: 'GETTING_STARTED_WITH_DOCUMENTS' }),
      },
    };

    return currentUserIdAdmin
      ? {
          ...staticPhases,
          tenant: {
            order: 3,
            label: intl.formatMessage({ id: 'EXPLORING_TENANT_SETTINGS' }),
          },
        }
      : staticPhases;
  }, [collaborative, currentUserIdAdmin]);

  const visibleSpace: Record<
    'all' | 'content' | 'actionBar' | 'sidebar' | 'tenantSidebar',
    NonNullable<VisibleSpace>[number]
  > = useMemo(
    () => ({
      all: {
        top: '0px',
        height: '100%',
        left: '0px',
        width: '100%',
      },
      content: {
        top: '0px',
        height: '100%',
        left: '200px',
        width: '100%',
      },
      actionBar: {
        top: '0px',
        height: '96px',
        left: '200px',
        width: '100%',
      },
      sidebar: {
        top: '0px',
        height: '100%',
        left: '0px',
        width: '200px',
      },
      tenantSidebar: {
        top: '0px',
        height: '100%',
        left: '56px',
        width: '200px',
      },
    }),
    [],
  );

  const cards = useMemo<ExtendedCard[]>(() => {
    const initialCards: ExtendedCard[] = [
      {
        id: 'beginning',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_STARTED' }),
        progress: 2.33,
        currentPhase: 1,
        box: {
          top: '8px',
          left: '8px',
          width: '184px',
          height: 'calc(100vh - 16px)',
        },
        autoAction: () => {
          navigateToMyFiles();
        },
        visibleSpace: [visibleSpace.sidebar],
      },
    ];

    const sidebarCards: ExtendedCard[] = [
      {
        id: 'sidebar_myFiles_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_SIDEBAR_MY_FILES_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.sidebar.myFiles' }),
        progress: 2.33,
        currentPhase: 1,
        subPhase: 1,
        showPhases: false,
        box: {
          top: '142px',
          left: '8px',
          width: '184px',
          height: '42px',
        },
        autoAction: () => {
          navigateToMyFiles();
        },
        visibleSpace: [visibleSpace.sidebar],
      },
      {
        id: 'sidebar_shared_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_SIDEBAR_SHARED_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.sidebar.shared' }),
        progress: 12.35,
        currentPhase: 1,
        subPhase: 2,
        showPhases: false,
        box: {
          top: '190px',
          left: '8px',
          width: '184px',
          height: '42px',
        },
        autoAction: () => {
          navigateToShared();
        },
        visibleSpace: [visibleSpace.sidebar],
      },
      {
        id: 'sidebar_spaces_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_SIDEBAR_SPACES_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.sidebar.spaces' }),
        progress: currentUserIdAdmin ? 16.28 : 24.71,
        currentPhase: 1,
        subPhase: 3,
        showPhases: false,
        box: {
          top: '239px',
          left: '8px',
          width: '184px',
          height: '42px',
        },
        autoAction: () => {
          navigateToSpaces();
        },
        visibleSpace: [visibleSpace.sidebar],
      },
      {
        id: 'sidebar_groups_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_SIDEBAR_GROUPS_DESCRIPTION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.sidebar.groups' }),
        progress: currentUserIdAdmin ? 24.42 : 37.06,
        currentPhase: 1,
        subPhase: 4,
        showPhases: false,
        box: {
          top: '287px',
          left: '8px',
          width: '184px',
          height: '42px',
        },
        autoAction: () => {
          navigateToGroups();
        },
        visibleSpace: [visibleSpace.sidebar],
      },
    ];

    const sidebarFinalCards: ExtendedCard[] = [
      {
        id: 'sidebar_completed',
        description: collaborative
          ? intl.formatMessage({ id: 'ONBOARDING_EXPLORER_COLAB_SIDEBAR_COMPLETED' })
          : intl.formatMessage({ id: 'ONBOARDING_EXPLORER_SIDEBAR_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'storage.sidebar.groups' }),
        progress: currentUserIdAdmin ? 33 : 50.42,
        currentPhase: 1,
        subPhase: 4,
        phaseCompleted: 1,
        autoAction: () => {
          navigateToGroups();
        },
      },
    ];

    const documentCards: ExtendedCard[] = [
      {
        id: 'action_bar_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_DESCRIPTION' }),
        progress: currentUserIdAdmin ? 36.6 : 54.11,
        currentPhase: 2,
        autoAction: () => {
          navigateToMyFiles();
        },
      },
      {
        id: 'action_bar_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_LOCATION' }),
        progress: currentUserIdAdmin ? 36.6 : 54.11,
        currentPhase: 2,
        showPhases: false,
        box: {
          top: '16px',
          left: '216px',
          height: '64px',
          right: '16px',
        },
        visibleSpace: [visibleSpace.actionBar],
      },
      {
        id: 'action_bar_new_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_NEW_LOCATION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 36.6 : 54.11,
        currentPhase: 2,
        subPhase: 1,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: [
          'action_bar_new',
          'action_bar_new_document',
          'action_bar_new_document_option',
        ],
        deps: ['explorer_myFiles_new_open'],
        interactions: ['explorer_my_files_action_bar_new'],
      },
      {
        id: 'action_bar_document_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_DOCUMENT_LOCATION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 40.23 : 60.47,
        currentPhase: 2,
        subPhase: 1,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_new_document_option', 'action_bar_new_document'],
        deps: ['explorer_my_files_document_option_click'],
        interactions: ['explorer_my_files_action_bar_new', 'explorer_document_option'],
      },
      {
        id: 'action_bar_document_create',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_DOCUMENT_CREATE' }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 46.74 : 70.35,
        currentPhase: 2,
        subPhase: 1,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_document_name', 'action_bar_document_created'],
        deps: ['explorer_my_files_document_created'],
        interactions: ['explorer_my_files_action_bar_new', 'explorer_document_create'],
        position: 'bottom-left',
      },
      {
        id: 'action_bar_share_location',
        description: intl.formatMessage(
          { id: 'ONBOARDING_EXPLORER_ACTION_BAR_SHARE_LOCATION' },
          { br: <br /> },
        ),
        phaseTitle: intl.formatMessage({ id: 'SHARE_DOCUMENT' }),
        progress: currentUserIdAdmin ? 53.26 : 80.23,
        currentPhase: 2,
        subPhase: 2,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_share'],
        deps: ['explorer_document_share_opened'],
        interactions: ['explorer_my_files_action_bar_share'],
        autoAction: () => {
          if (!collaborative) {
            dispatch(closeModal('ShareModal'));
          }
        },
      },
      {
        id: 'action_bar_share_with_user',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_SHARE_WITH_USER' }),
        phaseTitle: intl.formatMessage({ id: 'SHARE_DOCUMENT' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_SHARE_WITH_USER_TIP' }),
        progress: currentUserIdAdmin ? 59.77 : 80.23,
        currentPhase: 2,
        subPhase: 2,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: [
          'action_bar_share_user_name',
          'action_bar_share_user_role',
          'action_bar_share_finished',
        ],
        deps: ['explorer_share_user', 'explorer_share_role', 'explorer_share_finish'],
        interactions: ['explorer_share'],
        position: 'bottom-left',
      },
      {
        id: 'action_bar_share_completed',
        description: currentUserIdAdmin
          ? intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_SHARE_COMPLETED' })
          : intl.formatMessage({ id: 'ONBOARDING_EXPLORER_NOT_ADMIN_ACTION_BAR_SHARE_COMPLETED' }),
        phaseTitle: intl.formatMessage({ id: 'SHARE_DOCUMENT' }),
        progress: currentUserIdAdmin ? 66.6 : 100,
        currentPhase: 2,
        subPhase: 2,
        phaseCompleted: 2,
        autoAction: () => {
          navigateToMyFiles();
        },
      },
    ];

    const spaceCards: ExtendedCard[] = [
      {
        id: 'action_bar_description',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_COLAB_ACTION_BAR_DESCRIPTION' }),
        progress: currentUserIdAdmin ? 36.6 : 54.11,
        currentPhase: 2,
        autoAction: () => {
          navigateToSpaces();
        },
      },
      {
        id: 'action_bar_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_COLAB_ACTION_BAR_LOCATION' }),
        progress: currentUserIdAdmin ? 36.6 : 54.11,
        currentPhase: 2,
        showPhases: false,
        box: {
          top: '16px',
          left: '216px',
          height: '64px',
          right: '16px',
        },
        visibleSpace: [visibleSpace.actionBar],
      },
      {
        id: 'action_bar_new_space_location',
        description: intl.formatMessage({
          id: 'ONBOARDING_EXPLORER_ACTION_BAR_NEW_SPACE_LOCATION',
        }),
        phaseTitle: intl.formatMessage({ id: 'spaces.createSpace' }),
        progress: currentUserIdAdmin ? 36.6 : 54.11,
        currentPhase: 2,
        subPhase: 1,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_new_space'],
        deps: ['explorer_space_opened'],
        interactions: ['explorer_spaces_action_bar_new'],
      },
      {
        id: 'action_bar_space_create',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_SPACE_CREATE' }),
        phaseTitle: intl.formatMessage({ id: 'spaces.createSpace' }),
        progress: currentUserIdAdmin ? 38.08 : 56.07,
        currentPhase: 2,
        subPhase: 1,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_space_name', 'action_bar_space_created'],
        deps: ['explorer_spaces_space_created'],
        interactions: ['explorer_space_create'],
        position: 'bottom-left',
      },
      {
        id: 'action_bar_share_location',
        description: intl.formatMessage(
          { id: 'ONBOARDING_EXPLORER_ACTION_BAR_SPACE_SHARE_LOCATION' },
          { br: <br /> },
        ),
        phaseTitle: intl.formatMessage({ id: 'SHARE_SPACE' }),
        progress: currentUserIdAdmin ? 42.44 : 61.56,
        currentPhase: 2,
        subPhase: 2,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_spaces_share'],
        deps: ['explorer_space_share'],
        interactions: ['explorer_spaces_action_bar_share'],
        autoAction: () => {
          navigateToSpaces();
        },
      },
      {
        id: 'action_bar_share_with_user',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_SHARE_WITH_USER' }),
        phaseTitle: intl.formatMessage({ id: 'SHARE_SPACE' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_SHARE_WITH_USER_TIP' }),
        progress: currentUserIdAdmin ? 46.8 : 67.06,
        currentPhase: 2,
        subPhase: 2,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: [
          'action_bar_spaces_share_user_name',
          'action_bar_spaces_share_user_role',
          'action_bar_spaces_share_finished',
        ],
        deps: ['explorer_share_user', 'explorer_share_role', 'explorer_share_finish'],
        interactions: ['explorer_share'],
        position: 'bottom-left',
      },
      {
        id: 'action_bar_share_completed',
        description: intl.formatMessage({
          id: 'ONBOARDING_EXPLORER_ACTION_BAR_SPACE_SHARE_COMPLETED',
        }),
        phaseTitle: intl.formatMessage({ id: 'SHARE_SPACE' }),
        progress: currentUserIdAdmin ? 51.17 : 72.55,
        currentPhase: 2,
        subPhase: 2,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_spaces_share_closed'],
        position: 'bottom-left',
        deps: ['explorer_share_closed'],
        interactions: ['explorer_share_done'],
        autoAction: () => {
          navigateToSpaces();
        },
      },
      {
        id: 'action_bar_create_document_description',
        description: intl.formatMessage({
          id: 'ONBOARDING_EXPLORER_ACTION_BAR_CREATE_DOCUMENT_DESCRIPTION',
        }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 51.17 : 72.55,
        currentPhase: 2,
        subPhase: 3,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        deps: ['explorer_open_space'],
        pulseTarget: ['action_bar_space_location'],
        interactions: ['explorer_spaces_space_click'],
        autoAction: () => {
          navigateToSpaces();
        },
      },
      {
        id: 'action_bar_new_location',
        description: intl.formatMessage({
          id: 'ONBOARDING_EXPLORER_COLAB_ACTION_BAR_NEW_LOCATION',
        }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 55.52 : 78.04,
        currentPhase: 2,
        subPhase: 3,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_new', 'action_bar_new_document'],
        deps: ['explorer_myFiles_new_open'],
        interactions: ['explorer_my_files_action_bar_new'],
        autoAction: () => {
          if (actionsCompleted['explorer_open_space']) {
            navigateToObject('space', explorerFiles[0]);
          }
        },
      },
      {
        id: 'action_bar_document_location',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_DOCUMENT_LOCATION' }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 59.88 : 83.53,
        currentPhase: 2,
        subPhase: 3,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['action_bar_new_document_option', 'action_bar_new_document'],
        deps: ['explorer_my_files_document_option_click'],
        interactions: ['explorer_my_files_action_bar_new', 'explorer_document_option'],
      },
      {
        id: 'action_bar_document_create',
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_DOCUMENT_CREATE' }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 64.24 : 89.02,
        currentPhase: 2,
        subPhase: 3,
        showPhases: false,
        visibleSpace: [visibleSpace.all],
        position: 'bottom-left',
        pulseTarget: ['action_bar_document_name', 'action_bar_document_created'],
        deps: ['explorer_my_files_document_created'],
        interactions: ['explorer_my_files_action_bar_new', 'explorer_document_create'],
      },
      {
        id: 'action_bar_create_document_completed',
        description: currentUserIdAdmin
          ? intl.formatMessage({ id: 'ONBOARDING_EXPLORER_ACTION_BAR_CREATE_DOCUMENT_COMPLETED' })
          : intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_NOT_ADMIN_ACTION_BAR_CREATE_DOCUMENT_COMPLETED',
            }),
        phaseTitle: intl.formatMessage({ id: 'storage.modals.createDocument.createButton' }),
        progress: currentUserIdAdmin ? 66.6 : 100,
        currentPhase: 2,
        subPhase: 3,
        phaseCompleted: 2,
      },
    ];

    const gettingStartedCards: ExtendedCard[] = collaborative ? spaceCards : documentCards;

    const tenantSettings: ExtendedCard[] = currentUserIdAdmin
      ? [
          {
            id: 'tenant_settings_location',
            description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_LOCATION' }),
            progress: 69.99,
            currentPhase: 3,
            pulseTarget: ['tenant_settings_location'],
            deps: ['explorer_tenant_settings_click'],
            interactions: ['explorer_tenant_settings'],
            autoAction: () => {
              if (!collaborative) {
                dispatch(closeModal('ShareModal'));
                navigateToMyFiles();
              } else {
                if (explorerFiles.length > 0) {
                  navigateToObject('space', explorerFiles[0]);
                }
              }
            },
            visibleSpace: [visibleSpace.sidebar],
          },
          {
            id: 'tenant_settings_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_DESCRIPTION',
            }),
            progress: 69.99,
            currentPhase: 3,
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'general' }));
            },
          },
          {
            id: 'tenant_settings_general_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_GENERAL_DESCRIPTION',
            }),
            phaseTitle: intl.formatMessage({ id: 'settings.tabs.general' }),
            progress: 69.99,
            currentPhase: 3,
            subPhase: 1,
            showPhases: false,
            box: {
              top: '95px',
              left: '64px',
              width: '184px',
              height: '40px',
            },
            visibleSpace: [visibleSpace.tenantSidebar],
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'general' }));
            },
          },
          {
            id: 'tenant_settings_users_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_USERS_DESCRIPTION',
            }),
            phaseTitle: intl.formatMessage({ id: 'settings.permissions.users.title' }),
            progress: 72.88,
            currentPhase: 3,
            subPhase: 2,
            tipValue: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_USERS_DESCRIPTION_TIP',
            }),
            showPhases: false,
            box: {
              top: '142px',
              left: '64px',
              width: '184px',
              height: '40px',
            },
            visibleSpace: [visibleSpace.tenantSidebar],
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'users' }));
            },
          },
          {
            id: 'tenant_settings_roles_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_ROLES_DESCRIPTION',
            }),
            phaseTitle: intl.formatMessage({ id: 'global.roles' }),
            progress: 78.31,
            currentPhase: 3,
            subPhase: 3,
            showPhases: false,
            box: {
              top: '191px',
              left: '64px',
              width: '184px',
              height: '40px',
            },
            visibleSpace: [visibleSpace.tenantSidebar],
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'roles' }));
            },
          },
          {
            id: 'tenant_settings_tags_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_TAGS_DESCRIPTION',
            }),
            phaseTitle: intl.formatMessage({ id: 'settings.general.tags' }),
            progress: 83.75,
            currentPhase: 3,
            subPhase: 4,
            showPhases: false,
            box: {
              top: '240px',
              left: '64px',
              width: '184px',
              height: '40px',
            },
            visibleSpace: [visibleSpace.tenantSidebar],
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'tags' }));
            },
          },
          {
            id: 'tenant_settings_templates_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_TEMPLATES_DESCRIPTION',
            }),
            phaseTitle: intl.formatMessage({ id: 'TEMPLATES' }),
            progress: 89.12,
            currentPhase: 3,
            subPhase: 5,
            showPhases: false,
            box: {
              top: '335px',
              left: '64px',
              width: '184px',
              height: '40px',
            },
            visibleSpace: [visibleSpace.tenantSidebar],
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'templates' }));
            },
          },
          {
            id: 'tenant_settings_referencesStyles_description',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_REFERENCES_STYLES_DESCRIPTION',
            }),
            phaseTitle: intl.formatMessage({ id: 'REFERENCES_STYLES' }),
            progress: 94.62,
            currentPhase: 3,
            subPhase: 6,
            showPhases: false,
            box: {
              top: '383px',
              left: '64px',
              width: '184px',
              height: '40px',
            },
            visibleSpace: [visibleSpace.tenantSidebar],
            autoAction: () => {
              navigateToTenantSettings();
              dispatch(
                setMenuActiveOption({ menu: 'tenantSettings', activeOption: 'referencesStyles' }),
              );
            },
          },
          {
            id: 'tenant_settings_completed',
            description: intl.formatMessage({
              id: 'ONBOARDING_EXPLORER_TENANT_SETTINGS_COMPLETED',
            }),
            phaseTitle: intl.formatMessage({ id: 'REFERENCES_STYLES' }),
            progress: 100,
            currentPhase: 3,
            subPhase: 6,
            phaseCompleted: 3,
          },
        ]
      : [];

    const finalCards: ExtendedCard[] = [
      {
        id: 'ending',
        title: intl.formatMessage({ id: 'AND_THATS_JUST_THE_BEGINNING' }),
        description: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_COMPLETED' }, { br: <br /> }),
        showPhases: false,
        progress: 100,
        currentPhase: 0,
        nextLabel: intl.formatMessage({ id: 'GO_BACK_EXPLORER' }),
      },
    ];

    return [
      ...initialCards,
      ...sidebarCards,
      ...sidebarFinalCards,
      ...gettingStartedCards,
      ...tenantSettings,
      ...finalCards,
    ];
  }, [currentJourney, currentUserIdAdmin, explorerFiles]);

  const [initialCardIndex, setInitialCardIndex] = useState<number>();
  const [activeCard, setActiveCard] = useState<ExtendedCard>(cards[initialCardIndex ?? 0]);

  //Auto next
  useEffect(() => {
    if (activeCard.deps?.length && activeCard.deps.every((dep) => actionsCompleted[dep])) {
      onboardingRef.current?.next();
    }
  }, [actionsCompleted]);

  useEffect(() => {
    if (!userLoaded) {
      return;
    }
    /**
     * Shouldn't be necessary but added as a safeguard.
     *
     * Ignore initialPhase behaviour in the following states
     */
    const inactiveStates = ['beginning', 'ending'];
    if (initialPhase && !inactiveStates.includes(initialPhase)) {
      const initialCardIndex = cards.findIndex((card) => card.id === initialPhase);
      if (initialCardIndex > 0) {
        setActiveCard(cards[initialCardIndex]);
        setInitialCardIndex(initialCardIndex);

        const completedActions = cards
          .slice(0, initialCardIndex)
          .reduce<ExplorerActions[]>((allActions, card) => {
            if (card.deps) {
              allActions = [...allActions, ...card.deps];
            }

            return allActions;
          }, []);

        dispatch(completeActionList(completedActions));

        for (let i = initialCardIndex; i >= 0; i--) {
          const card = cards[i];
          if (card.autoAction) {
            card.autoAction();
            break;
          }
        }
      }
    }
  }, [userLoaded]);

  const handleJourney = () => {
    if (currentUserIdAdmin) {
      if (currentJourney === 'personalUser') {
        return 'personalTenantAdminUser';
      } else {
        return 'collaborativeTenantAdminUser';
      }
    } else {
      if (currentJourney === 'personalUser') {
        return 'personalGeneralUser';
      } else {
        return 'collaborativeGeneralUser';
      }
    }
  };

  useEffect(() => {
    //If initial phase has been cleaned up, also clean up local states
    if (!initialPhase) {
      setInitialCardIndex(undefined);
      setActiveCard(cards[0]);
      setCurrentJourney(undefined);
    }
  }, [initialPhase]);

  useEffect(() => {
    dispatch(setInteractions(activeCard.interactions ?? []));
  }, [activeCard]);

  useEffect(() => {
    if (activeCard.deps?.every((dep) => actionsCompleted[dep])) {
      const activeCardIndex = cards.findIndex((card) => card.id === activeCard.id);

      //Validate that the next card exists
      if (activeCardIndex + 1 <= cards.length) {
        sendOnboardingStatus({
          target: 'explorer',
          step: cards[activeCardIndex + 1].id,
          journey: handleJourney(),
        });
      }
    }
  }, [activeCard, actionsCompleted]);

  useEffect(() => {
    if (activeCard.pulseTarget) {
      activeCard.pulseTarget?.forEach(async (target) => {
        switch (target) {
          case 'action_bar_new': {
            setPulsePosition({
              position: {
                top: '72px',
                right: '83rem',
              },
              direction: 'up',
              boundary: null,
            });
            break;
          }
          case 'action_bar_new_document_option':
          case 'action_bar_new_document': {
            const { importDocumentOptionRect, documentOptionRect } = pulseData;
            if (!actionsCompleted.explorer_my_files_document_option_click) {
              if (documentOptionRect) {
                setPulsePosition({
                  position: {
                    top: `${documentOptionRect.top + 99}px`,
                    left: `${documentOptionRect.left + 173}px`,
                  },
                  direction: 'left',
                  boundary: null,
                });
              } else if (importDocumentOptionRect) {
                setPulsePosition({
                  position: {
                    top: `${importDocumentOptionRect.top + 102}px`,
                    right: `207px`,
                  },
                  direction: 'left',
                  boundary: null,
                });
              }
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'action_bar_new_space': {
            if (!actionsCompleted.explorer_space_opened) {
              setPulsePosition({
                position: {
                  top: '71px',
                  right: '73rem',
                },
                direction: 'up',
                boundary: null,
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'action_bar_document_name':
          case 'action_bar_document_created': {
            const { documentInputRect, documentButtonRect } = pulseData;
            if (documentInputRect) {
              setPulsePosition({
                position: {
                  top: `${documentInputRect.top + 205}px`,
                  left: `${documentInputRect.left + 24}px`,
                },
                direction: 'right',
                boundary: document.getElementById('create-object-modal'),
              });
            } else if (documentButtonRect) {
              setPulsePosition({
                position: {
                  bottom: `0px`,
                  left: `${documentButtonRect.left + documentButtonRect.width / 2}px`,
                },
                direction: 'up',
                boundary: document.getElementById('create-object-modal'),
              });
            }
            break;
          }
          case 'action_bar_space_name':
          case 'action_bar_space_created': {
            const { spaceInputRect, spaceButtonRect } = pulseData;
            if (spaceInputRect) {
              setPulsePosition({
                position: {
                  top: `${spaceInputRect.top + 155}px`,
                  left: `${spaceInputRect.left + 24}px`,
                },
                direction: 'right',
                boundary: document.getElementById('create-new-object-modal'),
              });
            } else if (spaceButtonRect) {
              setPulsePosition({
                position: {
                  bottom: `0px`,
                  left: `${spaceButtonRect.left + spaceButtonRect.width / 2}px`,
                },
                direction: 'up',
                boundary: document.getElementById('create-new-object-modal'),
              });
            }
            break;
          }
          case 'action_bar_share': {
            if (!actionsCompleted.explorer_document_share_opened) {
              setPulsePosition({
                position: {
                  top: '71px',
                  right: '59rem',
                },
                direction: 'up',
                boundary: document.getElementById('ExplorerStoragePage'),
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'action_bar_share_user_name':
          case 'action_bar_share_user_role':
          case 'action_bar_share_finished': {
            const { shareInputRect, shareRoleRect, shareButtonRect } = pulseData;
            if (!actionsCompleted.explorer_share_finish) {
              if (shareInputRect) {
                setPulsePosition({
                  position: {
                    top: `${shareInputRect.top + 18}px`,
                    left: `${shareInputRect.left - 8}px`,
                  },
                  direction: 'right',
                  boundary: document.getElementById('share-container'),
                });
              } else if (shareRoleRect) {
                setPulsePosition({
                  position: {
                    top: `${shareRoleRect.top + shareRoleRect.height + 8}px`,
                    left: `${shareRoleRect.left + shareRoleRect.width / 2}px`,
                  },
                  direction: 'up',
                  boundary: document.getElementById('share-container'),
                });
              } else if (shareButtonRect) {
                setPulsePosition({
                  position: {
                    top: `${shareButtonRect.top + shareButtonRect.height + 8}px`,
                    left: `${shareButtonRect.left + shareButtonRect.width / 2}px`,
                  },
                  direction: 'up',
                  boundary: document.getElementById('share-container'),
                });
              } else {
                setPulsePosition(undefined);
              }
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'action_bar_spaces_share': {
            if (!actionsCompleted.explorer_space_share) {
              setPulsePosition({
                position: {
                  top: '72px',
                  right: '49.5rem',
                },
                direction: 'up',
                boundary: null,
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'action_bar_spaces_share_user_name':
          case 'action_bar_spaces_share_user_role':
          case 'action_bar_spaces_share_finished': {
            const { shareInputRect, shareRoleRect, shareButtonRect } = pulseData;
            if (!actionsCompleted.explorer_share_finish) {
              if (shareInputRect) {
                setPulsePosition({
                  position: {
                    top: `${shareInputRect.top + 18}px`,
                    left: `${shareInputRect.left - 8}px`,
                  },
                  direction: 'right',
                  boundary: document.getElementById('share-container'),
                });
              } else if (shareRoleRect) {
                setPulsePosition({
                  position: {
                    top: `${shareRoleRect.top + shareRoleRect.height + 8}px`,
                    left: `${shareRoleRect.left + shareRoleRect.width / 2}px`,
                  },
                  direction: 'up',
                  boundary: document.getElementById('share-container'),
                });
              } else if (shareButtonRect) {
                setPulsePosition({
                  position: {
                    top: `${shareButtonRect.top + shareButtonRect.height + 8}px`,
                    left: `${shareButtonRect.left + shareButtonRect.width / 2}px`,
                  },
                  direction: 'up',
                  boundary: document.getElementById('share-container'),
                });
              }
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'action_bar_spaces_share_closed': {
            const { shareDoneButtonRect } = pulseData;
            if (shareDoneButtonRect) {
              setPulsePosition({
                position: {
                  bottom: `0px`,
                  left: `${shareDoneButtonRect.left + shareDoneButtonRect.width / 2}px`,
                },
                direction: 'up',
                boundary: document.getElementById('share-modal'),
              });
            }
            break;
          }
          case 'action_bar_space_location': {
            if (!actionsCompleted.explorer_open_space) {
              setPulsePosition({
                position: {
                  top: '167px',
                  left: '240px',
                },
                direction: 'right',
                boundary: null,
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          case 'tenant_settings_location': {
            if (currentPage !== '/settings') {
              setPulsePosition({
                position: {
                  bottom: '100px',
                  left: '138px',
                },
                direction: 'left',
                boundary: document.getElementById('ExplorerSidebarContent'),
              });
            } else {
              setPulsePosition(undefined);
            }
            break;
          }
          default: {
            setPulsePosition(undefined);
            break;
          }
        }
      });
    } else {
      setPulsePosition(undefined);
    }
  }, [activeCard, cards, pulseData, actionsCompleted]);

  const handleOnboardingFiles = () => {
    if (explorerFiles && explorerFiles?.length > 0) {
      const object = data[explorerFiles[0]];
      const params = {
        objectId: object.id,
        objectType: object.type,
        name: object.name,
      };
      new InstanceService().delete(params).then(() => {
        const identity = currentJourney === 'personalUser' ? 'storage' : 'spaces';
        dispatch(clearSelection());
        dispatch(removeFromList({ identity, objectId: params.objectId }));
      });
    }
  };

  const handleEnding = () => {
    sendOnboardingStatus({ target: 'explorer', step: 'ending' });
    dispatch(stopOnboarding('explorer'));
    setSkipping(false);
    navigateToMyFiles();
    handleOnboardingFiles();
    dispatch(closeAllModals());
  };

  const handleSkip = () => {
    sendOnboardingStatus({ target: 'explorer', step: 'ending', skip: true });
    dispatch(stopOnboarding('explorer'));
    dispatch(deActivateOnboarding('explorer'));
    setSkipping(false);
    navigateToMyFiles();
    handleOnboardingFiles();
    dispatch(closeAllModals());
  };
  const allow =
    currentPage === '/storage' ||
    currentPage === '/shared' ||
    currentPage === '/storage/spaces' ||
    currentPage === '/groups' ||
    currentPage === '/settings';

  const onCancelSkip = () => {
    setSkipping(false);
  };

  const handleNext = (newActiveCard: number) => {
    //Make sure requirements are completed by forcing their execution (fixes restart using back)
    cards[newActiveCard].autoAction?.();

    setActiveCard(cards[newActiveCard]);
    if (cards[newActiveCard].id !== 'ending') {
      sendOnboardingStatus({ target: 'explorer', step: cards[newActiveCard].id });
    }
  };

  const handleBack = (newActiveCard: number) => {
    cards[newActiveCard].autoAction?.();
    if (activeCard.id === 'beginning') {
      dispatch(activateOnboarding('explorer'));
      dispatch(stopOnboarding('explorer'));
    } else {
      setActiveCard(cards[newActiveCard]);
    }
  };

  if (!started && !active) {
    return null;
  }

  if (!allow) {
    return null;
  }

  const renderPulse = () => {
    return (
      pulsePosition?.position &&
      (pulsePosition.boundary ? (
        ReactDOM.createPortal(
          <Pulse
            position={pulsePosition.position}
            direction={pulsePosition.direction}
            testId="explorer"
          />,
          pulsePosition.boundary,
        )
      ) : (
        <Pulse
          position={pulsePosition.position}
          direction={pulsePosition.direction}
          testId="explorer"
        />
      ))
    );
  };

  return (
    <>
      {(active || (started && skipping)) &&
        ReactDOM.createPortal(
          <WelcomeScreen
            location="explorer"
            onSkip={setSkipping}
            labelsId={{
              title: 'WELCOME_TO_DODOC_EXPLORER',
              description: 'WELCOME_EXPLORER_DESCRIPTION',
              cta: 'LETS_BEGIN',
            }}
            hiddenElements={['ExplorerSidebarContent', 'ExplorerStoragePage']}
            absoluteOverlay={false}
            questionnaire={{
              isOpen: active && !!currentJourney,
              question: { id: 'DO_YOU_NEED_TO_CREATE_DOCUMENTS_FOR_DIFFERENT_PROJECTS_CLIENTS' },
              answers: {
                collaborativeUser: {
                  id: 'YES_I_NEED_TO_CREATE_DOCUMENTS_FOR_MULTIPLE_PROJECTS_CLIENTS',
                },
                personalUser: {
                  id: 'NO_I_ONLY_NEED_TO_CREATE_DOCUMENTS_FOR_A_SINGLE_PROJECT_CLIENT',
                },
              },
              defaultAnswer: currentJourney,
              setAnswer: (answerId) => {
                setCurrentJourney(answerId);
              },
            }}
          />,
          document.getElementById('WelcomeScreen') ?? document.body,
        )}
      <OnboardingOverlay environment="explorer">
        <span
          style={{
            visibility: (!started && !skipping) || !userLoaded ? 'hidden' : 'visible',
          }}
        >
          <OnboardingIntegrationCards
            ref={onboardingRef}
            initialCard={initialCardIndex}
            previousLabel={intl.formatMessage({ id: 'BACK' })}
            next={intl.formatMessage({ id: 'NEXT' })}
            phases={phases}
            title={intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' })}
            skipButtonLabel={intl.formatMessage({ id: 'SKIP_ONBOARDING' })}
            cards={cards}
            onExit={handleSkip}
            onFinalExit={handleEnding}
            onNext={handleNext}
            onBack={handleBack}
            skipPreviousLabel={intl.formatMessage({ id: 'NO_CONTINUE_ONBOARDING' })}
            skipFinishLabel={intl.formatMessage({ id: 'YES_SKIP' })}
            skipHeader={intl.formatMessage({ id: 'SKIP_THE_ONBOARDING_QUESTION' })}
            skipContent={intl.formatMessage(
              {
                id: intl.formatMessage({ id: 'ONBOARDING_EXPLORER_SKIP_CONFIRMATION' }),
              },
              { br: <br /> },
            )}
            nextDisabled={
              !!activeCard.deps && !activeCard.deps.every((dep) => actionsCompleted[dep])
            }
            nextDisabledTooltip={intl.formatMessage({ id: 'ONBOARDING_PENDING_ACTION_TOOLTIP' })}
            tip={intl.formatMessage({ id: 'TIP_COLON' })}
            cancelSkip={skipping ? onCancelSkip : undefined}
            skip={skipping}
            testId="explorer"
            pulse={renderPulse()}
          />
        </span>
      </OnboardingOverlay>
    </>
  );
};

export default OnboardingExplorer;
